import { ThemeConfig } from "antd/es/config-provider"

const theme: ThemeConfig = {
  inherit: false,
  token: {
    fontFamily: "Manrope",
  },
  components: {
    Card: {
      colorBgContainer: "#0F1216",
      borderRadius: 16,
      colorBorderSecondary: "transparent",
    },
    Select: {
      colorBgContainer: 'transparent',
      colorBorder: '#242629',
      borderRadius: 12,
      fontSize: 16,
      colorTextPlaceholder: '#6E7072',
      colorText: '#6E7072',
      controlHeight: 40,
    },
    Button: {
      colorPrimaryBg: "#0560FF",
    },
    Typography: {
      fontSizeHeading1: 52,
      fontSizeHeading2: 36,
      fontSizeHeading3: 28,
      lineHeightHeading1: 0.95,
      lineHeightHeading2: 0.83,
      lineHeightHeading3: 0.83,
    },
    Checkbox: {
      borderRadius: 2,
      colorBorder: "#33383F",
      colorBgBase: "#0F1216",
      colorBgContainer: "#0F1216",
      borderRadiusLG: 2,
      borderRadiusSM: 2,
      borderRadiusXS: 2,
      borderRadiusOuter: 2,
    },
    Menu: {
      padding: 0,
      colorBorderBg: "unset",
      colorActiveBarHeight: 0,
      colorPrimaryBg: "unset",
    },
    Layout: {
      colorBgLayout: "unset",
      headerBg: "unset",
      bodyBg: "unset",
    },
  },
}

export default theme
