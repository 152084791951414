import { FC, lazy } from "react"
import { Navigate, Outlet, RouteObject } from "react-router-dom"
import Loader from "src/components/layouts/Loader"
import DefaultLayout from "src/components/layouts/DefaultLayout"
import EnhanceAndShareLayout from "src/components/layouts/EnhanceAndShareLayout"
import AuthLayout from "src/components/layouts/AuthLayout"
import { Routes } from "src/types/routes"
import withAuth from "src/hoc/withAuth"
import FullScreenLoader from "src/components/ui/FullScreenLoader"

const Home = lazy<FC>(() => import("./home"))
const SignUp = lazy<FC>(() => import("./sign-up"))
const SignIn = lazy<FC>(() => import("./sign-in"))
const Dashboard = lazy<FC>(() => import("./dashboard"))
const EnhanceAndShare = lazy<FC>(() => import("./enhance-and-share"))
const SearchAndDownload = lazy<FC>(() => import("./search-and-download"))
const MyLeads = lazy<FC>(() => import("./my-leads"))
const LearningCenter = lazy<FC>(() => import("./learning-center"))
const ForgotPassword = lazy<FC>(() => import("./forgot-password"))
const ResetPassword = lazy<FC>(() => import("./reset-password"))
const DefineSearch = lazy<FC>(() => import("./search-and-download/define-search"))
const Preview = lazy<FC>(() => import("./search-and-download/preview"))
const DownloadLeads = lazy<FC>(() => import("./search-and-download/download"))
const AllLeads = lazy<FC>(() => import("./my-leads/all-leads"))
const MyLeadsDownloads = lazy<FC>(() => import("./my-leads/downloads"))
const MyLeadsUploads = lazy<FC>(() => import("./my-leads/uploads"))
const MyLeadsCampaigns = lazy<FC>(() => import("./my-leads/campaigns"))
const PersonalizedCommunications = lazy<FC>(() => import("./my-leads/campaigns/personalized-communitactions"))
const VideoTutorials = lazy<FC>(() => import("./learning-center/video-tutorials"))
const DataDictionary = lazy<FC>(() => import("./learning-center/data-dictionary"))
const Profile = lazy<FC>(() => import("./profile"))
const UserData = lazy<FC>(() => import("./profile/user-data"))
const Password = lazy<FC>(() => import("./profile/password"))
const Credits = lazy<FC>(() => import("./profile/credits-and-payments"))
const ConfirmEmail = lazy<FC>(() => import("./cofirm-email"))

const routes: RouteObject[] = [
  {
    Component: DefaultLayout,
    loader: () => <FullScreenLoader />,
    children: [
      {
        Component: Home,
        path: "",
        index: true,
      },
    ]
  },
  {
    Component: withAuth(EnhanceAndShareLayout),
    loader: () => <FullScreenLoader />,
    children: [
      {
        Component: Dashboard,
        path: Routes.DASHBOARD,
        index: true,
      },
      {
        Component: EnhanceAndShare,
        path: Routes.ENHANCE_AND_SHARE,
      },
      {
        Component: SearchAndDownload,
        path: Routes.SEARCH_AND_DOWNLOAD,
        children: [
          {
            path: "",
            element: <Navigate to={Routes.LEADS_DEFINE_SEARCH} replace />,
          },
          {
            Component: DefineSearch,
            path: Routes.LEADS_DEFINE_SEARCH,
          },
          {
            Component: Preview,
            path: Routes.LEADS_PREVIEW,
          },
          {
            Component: DownloadLeads,
            path: Routes.LEADS_DOWNLOAD,
          },
        ]
      },
      {
        Component: MyLeads,
        path: Routes.MY_LEADS,
        children: [
          {
            path: "",
            element: <Navigate to={Routes.MY_LEADS_ALL_LEADS} replace />,
          },
          {
            Component: AllLeads,
            path: Routes.MY_LEADS_ALL_LEADS,
          },
          {
            Component: MyLeadsDownloads,
            path: Routes.MY_LEADS_DOWNLOAD,
          },
          {
            Component: MyLeadsUploads,
            path: Routes.MY_LEADS_UPLOAD,
          },
          {
            Component: MyLeadsCampaigns,
            path: Routes.MY_LEADS_CAMPAIGN,
          },
          {
            Component: PersonalizedCommunications,
            path: Routes.PERSONALIZED_CAMPAIGN,
          }
        ]
      },
      {
        Component: LearningCenter,
        path: Routes.LEARNING_CENTER,
        children: [
          {
            path: "",
            element: <Navigate to={Routes.LEARNING_CENTER_DATA_DICTIONARY} replace />,
          },
          {
            Component: DataDictionary,
            path: Routes.LEARNING_CENTER_DATA_DICTIONARY,
          },
          {
            Component: VideoTutorials,
            path: Routes.LEARNING_CENTER_VIDEO_TUTORIALS,
          },
          {
            Component: Outlet,
            path: "*",
          }
        ]
      },
      {
        Component: Profile,
        path: Routes.PROFILE,
        children: [
          {
            path: "",
            element: <Navigate to={Routes.PROFILE_USER_DATA} replace />,
          },
          {
            Component: UserData,
            path: Routes.PROFILE_USER_DATA,
          },
          {
            Component: Password,
            path: Routes.PROFILE_CHANGE_PASSWORD,
          },
          {
            Component: Credits,
            path: Routes.PROFILE_CREDITS,
          }
        ]
      },
      {
        Component: Outlet,
        path: "*",
      },
    ],
  },
  {
    Component: AuthLayout,
    loader: () => <FullScreenLoader />,
    children: [
      {
        Component: SignUp,
        path: Routes.SIGN_UP,
      },
      {
        Component: SignIn,
        path: Routes.SIGN_IN,
      },
      {
        Component: ForgotPassword,
        path: Routes.FORGOT_PASSWORD,
      },
      {
        Component: ResetPassword,
        path: Routes.RESET_PASSWORD,
      },
      {
        Component: ConfirmEmail,
        path: Routes.CONFIRM_EMAIL,
      },
      {
        Component: Outlet,
        path: "*",
      }
    ],
  }
]

export default routes
