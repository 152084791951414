import { Col, Row } from "antd"
import { FC } from "react"
import { Logo } from "src/components/logo"
import NavigationMenu from "src/components/navigation/Navigation"
import { UserProfileButton } from "src/components/user-profile-button/UserProfileButton"
import './Header.less'

const Header: FC<{ renderMenu?: FC }> = () => {
  return (
    <Row align={"middle"} justify={"space-between"}>
      <Col span={24} className={'header'}>
        <NavigationMenu />

        <Logo 
          wrapperProps={{
            className: 'logo-wrapper',
          }} 
        />


        <UserProfileButton />
      </Col>
    </Row>
  )
}

export { Header }
