import { FC, Suspense } from "react";
import { Layout as BaseLayout, Col, Image, Row, Typography } from "antd";
import { Outlet } from "react-router";
import lamp from 'src/assets/images/lamp.webp'
import fog from 'src/assets/images/fog.webp'
import { SvgIcon } from "src/components/icon";
import { Logo } from "src/components/logo";
import './Layout.less'
import Loader from "../Loader";

const Layout: FC = () => (
  <BaseLayout className={'auth-layout'}>
    <div className={'logo-wrapper'}>
      <Logo />

      <Typography.Text className={'logo-title'}>
        Franchise Lead Genie
      </Typography.Text>
    </div>
    <Row className={'container'} justify={'end'}>
      <Col span={12} className={'side-images-container'}>
        <div className={'images-container'}>
          <Image width={588} wrapperClassName={'lamp-wrapper'} preview={false} src={lamp} />
          <Image width={860} wrapperClassName={'fog-wrapper'} preview={false} src={fog} />
        </div>

        <Typography.Text className={'side-image-title heading-3'}>
          Unlock the full potential of every lead <SvgIcon type={'blueStars'} />
        </Typography.Text>
      </Col>

      <Col span={12} className={'content-container'}>
        <Suspense fallback={<Loader spinning size={'large'} />}>
          <Outlet />
        </Suspense>
      </Col>
    </Row>
  </BaseLayout>
)

export { Layout }