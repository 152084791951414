import { Col, Divider, Row, Typography } from "antd"
import { FC } from "react"
import './Footer.less'
import { Logo } from "src/components/logo"
import { Link } from "react-router-dom"


const Footer: FC = () => {
  return (
    <Row justify={"space-between"} className={'landing-footer'}>
      <Col span={24} className={'footer'}>
        <div className={'logo-wrapper'}>
          <Logo />

          <Typography.Text className={'title'}>
            Franchise Lead Genie
          </Typography.Text>
        </div>

        <div className={'divider'} />


        <div className={'footer-links'}>
          <Link to={'/'} className={'footer-link'}>
            Privacy Policy
          </Link>

          <div className={'split-dot'} />

          <Link to={'/'} className={'footer-link'}>
            Terms of Service
          </Link>
        </div>
      </Col>

      <Col span={24} className={'copyright'}>
        <Typography.Text className={'copyright-text'}>
          © Franchise Lead Genie. All rights reserved.
        </Typography.Text>
      </Col>
    </Row>
  )
}

export { Footer }
