import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { BASE_URL } from 'src/consts';
import { getToken } from 'src/hooks/useAuthToken';

const fetchClient: AxiosInstance = axios.create({
  baseURL: `${BASE_URL}/api/v1`,
  headers: {
    'Content-Type': 'application/json',
  },
});

fetchClient.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default fetchClient;
