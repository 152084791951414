import { FC, ComponentType, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FullScreenLoader from "src/components/ui/FullScreenLoader";
import { useAuth } from "src/contexts/AuthProvider";
import { removeToken } from "src/hooks/useAuthToken";
import { Routes } from "src/types/routes";

const withAuth = <P extends object>(WrappedComponent: ComponentType<P>): FC<P> => {
  const ComponentWithAuth: FC<P> = (props) => {
    const { isAuthenticated, isLoading } = useAuth();
    
    const navigate = useNavigate();

    useEffect(() => {
      if (!isLoading && !isAuthenticated) {
        removeToken()
        navigate(Routes.SIGN_IN, { replace: true, state: { from: location.pathname } });
      }
    }, [isLoading, isAuthenticated, navigate]);

    if (isLoading) {
      return <FullScreenLoader />
    }

    return <WrappedComponent {...props} />;
  };

  return ComponentWithAuth;
};

export default withAuth;
