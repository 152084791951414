import { Layout as BaseLayout } from "antd"
import { FC, Suspense } from "react"
import { Outlet } from "react-router-dom"
import './Layout.less'
import { Header } from "./Header"
import { Footer } from "./Footer"
import FullScreenLoader from "src/components/ui/FullScreenLoader"

const Layout: FC = () => (
  <BaseLayout className={'default-layout'}>
    <BaseLayout.Header className={'header-wrapper'}>
      <Header />
    </BaseLayout.Header>
    <BaseLayout.Content className={'content-wrapper'}>
      <Suspense fallback={<FullScreenLoader />}>
        <Outlet />
      </Suspense>
    </BaseLayout.Content>
    <Footer />
  </BaseLayout>
)

export { Layout }
