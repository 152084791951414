import axios from 'axios';
import fetchClient from './fetchClient';

export interface LoginValues {
  email: string;
  password: string;
}

export interface RegisterValues {
  email: string;
  password: string;
}

export interface ConfirmEmailValues {
  code: string;
}

export interface ForgotPasswordValues {
  email: string;
}

export interface ResetPasswordValues {
  token: string;
  password: string;
  confirmPassword: string;
}

export const login = async (values: LoginValues) => {
  const res = await fetchClient.post('/auth/login', {
    email: values.email.trim(),
    password: values.password.trim(),
  });
  return res.data;
};

export const register = async (values: RegisterValues) => {
  const res = await fetchClient.post('/auth/register', {
    email: values.email.trim(),
    password: values.password.trim(),
  });
  return res.data;
};

export const confirmEmail = async (values: ConfirmEmailValues) => {
  const res = await fetchClient.post('/auth/register/confirm', {
    code: values.code,
  });
  return res.data;
}

export const confirmNewEmail = async (values: ConfirmEmailValues) => {
  const res = await fetchClient.post('/user/profile/update/confirm-email', {
    code: values.code,
  });
  return res.data;
};

export const forgotPassword = async (values: ForgotPasswordValues) => {
  const res = await fetchClient.post('/auth/forgot-password', {
    email: values.email,
  });
  return res.data;
};

export const resetPassword = async (values: ResetPasswordValues) => {
  const res = await fetchClient.post('/auth/reset-password', {
    token: values.token,
    password: values.password.trim(),
    confirmPassword: values.confirmPassword.trim(),
  });
  return res.data;
};

export const getUser = async () => {
  try {
    const res = await fetchClient.get('/user/profile');
    return res.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      
      return null;
    }
    throw error;
  }
};
