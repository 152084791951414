import { ConfigProvider, Form, FormInstance } from "antd"
import { createContext, memo, useContext, useRef, type FC, type PropsWithChildren } from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { version } from "../../../package.json"
import routes from "../../pages"
import theme from "../../themes"
import '/src/styles/main.less'
import './index.less'
import 'antd/dist/reset.css'
import { AuthProvider } from "src/contexts/AuthProvider"
import { QueryClient, QueryClientProvider } from "react-query"

type ContextProps = {
  app: { version: string },
  enhanceAndShare: { formData: FormInstance<any> },
}

const app: ContextProps = { 
  app: { version },
  enhanceAndShare: { formData: {} as FormInstance<any> },
 }

const Context = createContext(app)

const ContextProvider: FC<PropsWithChildren<ContextProps>> = ({ children, ...props }) => {
  const [form] = Form.useForm()

  const formRef = useRef<FormInstance<any>>(form)

  return <Context.Provider 
    value={{ 
      enhanceAndShare: {
        formData: formRef.current,
      },
      app: { ...props.app }
    }}
    >
    {children}
  </Context.Provider>
}

const useApp: () => ContextProps = () => useContext(Context)

const router = createBrowserRouter(routes)
const queryClient = new QueryClient();

const App: FC = memo(() => (
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <ContextProvider {...app}>
        <ConfigProvider theme={theme}>
          <RouterProvider router={router} />
        </ConfigProvider>
      </ContextProvider>
    </AuthProvider>
  </QueryClientProvider>
))

export { useApp }

export default App
