import { useMutation, useQuery, useQueryClient } from 'react-query';
import { 
  login, 
  register, 
  confirmEmail, 
  confirmNewEmail,
  forgotPassword, 
  resetPassword, 
  getUser 
} from '../api/authApi';
import { getToken, removeToken, setToken } from 'src/hooks/useAuthToken';
import { App } from 'src/types';
import { Routes } from 'src/types/routes';

export const useAuthApi = () => {
  const queryClient = useQueryClient();

  const userQuery = useQuery<App.User | null, Error>('user', getUser, {
    enabled: !!getToken(),
    onSuccess: () => {
      queryClient.setQueryData('isAuthenticated', true);
    },
    onError: () => {
      queryClient.setQueryData('isAuthenticated', false);
      removeToken();
    },
  });

  const loginMutation = useMutation(login, {
    onSuccess: (data) => {
      setToken(data.token);
      queryClient.invalidateQueries('user');
    }
  });

  const registerMutation = useMutation(register);
  const confirmEmailMutation = useMutation(confirmEmail);
  const confirmNewEmailMutation = useMutation(confirmNewEmail);
  const forgotPasswordMutation = useMutation(forgotPassword);
  const resetPasswordMutation = useMutation(resetPassword);

  const logout = () => {
    removeToken();
    queryClient.invalidateQueries('user');
    window.location.replace(Routes.SIGN_IN)
  };

  const isLoading = loginMutation.isLoading 
    || registerMutation.isLoading 
    || confirmEmailMutation.isLoading 
    || forgotPasswordMutation.isLoading 
    || resetPasswordMutation.isLoading
    || userQuery.isLoading
    || confirmNewEmailMutation.isLoading

  return {
    user: userQuery.data,
    isAuthenticated: !!userQuery.data,
    isLoading,
    login: loginMutation.mutate,
    register: registerMutation.mutate,
    confirmEmail: confirmEmailMutation.mutate,
    confirmNewEmail: confirmNewEmailMutation.mutate,
    forgotPassword: forgotPasswordMutation.mutate,
    resetPassword: resetPasswordMutation.mutate,
    logout,
  };
};
