import Loader from 'src/components/layouts/Loader'
import './index.less'

const FullScreenLoader = () => {
  return (
    <div className={"fullscreen-loader"}>
      <Loader spinning size={'large'} />
    </div>
  )
}

export default FullScreenLoader