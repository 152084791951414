import { createContext, FC, PropsWithChildren, useContext, useState } from "react";
import { useAuthApi } from '../hooks/useAuthApi';
import { App } from 'src/types';
import { AxiosError } from "axios";

type AuthContextProps = {
  register: (values: { email: string, password: string }) => Promise<any>;
  login: (values: { email: string, password: string }) => Promise<any>;
  confirmEmail: (values: { code: string }) => Promise<any>;
  confirmNewEmail: (values: { code: string }) => Promise<any>;
  forgotPassword: (values: { email: string }) => Promise<any>;
  resetPassword: (values: { token: string, confirmPassword: string, password: string }) => Promise<any>;
  logout: () => void;
  isAuthenticated: boolean;
  isLoading: boolean;
  user: App.User | null | undefined;
  error: AxiosError<any> | null;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuthContext must be used within an AuthProvider");
  }

  return context;
}

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    login: mutateLogin,
    register: mutateRegister,
    confirmEmail: mutateConfirmEmail,
    confirmNewEmail: mutateConfirmNewEmail,
    forgotPassword: mutateForgotPassword,
    resetPassword: mutateResetPassword,
    logout,
    isAuthenticated,
    isLoading,
    user,
  } = useAuthApi();

  const [error, setError] = useState<AxiosError<any> | null>(null);

  const handleMutationError = (error: any) => {
    setError(error || 'Something went wrong. Please try again later.');
  };

  const login = async (values: { email: string, password: string }): Promise<any> => {
    setError(null);
    return new Promise((resolve, reject) => {
      mutateLogin(values, {
        onSuccess: (data) => resolve(data),
        onError: (error) => {
          handleMutationError(error);
          reject(error);
        },
      });
    });
  };

  const register = async (values: { email: string, password: string }): Promise<any> => {
    setError(null);
    return new Promise((resolve, reject) => {
      mutateRegister(values, {
        onSuccess: (data) => resolve(data),
        onError: (error) => {
          handleMutationError(error);
          reject(error);
        },
      });
    });
  };

  const confirmEmail = async (values: { code: string }): Promise<any> => {
    setError(null);
    return new Promise((resolve, reject) => {
      mutateConfirmEmail(values, {
        onSuccess: (data) => resolve(data),
        onError: (error) => {
          handleMutationError(error);
          reject(error);
        },
      });
    });
  };

  const confirmNewEmail = async (values: { code: string }): Promise<any> => {
    setError(null);
    return new Promise((resolve, reject) => {
      mutateConfirmNewEmail(values, {
        onSuccess: (data) => resolve(data),
        onError: (error) => {
          handleMutationError(error);
          reject(error);
        },
      });
    });
  };

  const forgotPassword = async (values: { email: string }): Promise<any> => {
    setError(null);
    return new Promise((resolve, reject) => {
      mutateForgotPassword(values, {
        onSuccess: (data) => resolve(data),
        onError: (error) => {
          handleMutationError(error);
          reject(error);
        },
      });
    });
  };

  const resetPassword = async (values: { token: string, confirmPassword: string, password: string }): Promise<any> => {
    setError(null);
    return new Promise((resolve, reject) => {
      mutateResetPassword(values, {
        onSuccess: (data) => resolve(data),
        onError: (error) => {
          handleMutationError(error);
          reject(error);
        },
      });
    });
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        register,
        confirmEmail,
        confirmNewEmail,
        forgotPassword,
        resetPassword,
        logout,
        isAuthenticated,
        isLoading,
        user,
        error,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
