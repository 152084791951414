import { Space, SpaceProps } from "antd"
import { FC, SVGProps } from "react"

import { ReactComponent as LogoImage } from "./Logo.svg"

type Props = {
  wrapperProps?: SpaceProps
  svgProps?: SVGProps<SVGSVGElement>
}
const Logo: FC<Props> = ({ wrapperProps, svgProps }) => {
  return (
    <Space {...wrapperProps} direction={"horizontal"} align={"center"} style={{ cursor: "pointer" }}>
      <LogoImage {...svgProps} onClick={() => window.location.assign("/")} style={{ height: 32, width: 32 }} />
    </Space>
  )
}

export { Logo }
